<template>
    <div class="box" v-loading.fullscreen.lock="fullscreenLoading">
        <Navigation />
        <div class="c_box">
            <div style="height: 80px;"></div>
            <div class="bread flex">
                <p class="bread_title">当前位置：</p>
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/guide' }">技巧指南</el-breadcrumb-item>
                    <el-breadcrumb-item>正文</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <el-row class="create_box" type="flex" justify="space-between">
                <el-col :span="18">
                    <div class="ct_box">
                        <h3 class="title">{{ Detail.title }}</h3>
                        <p class="ctime">发布于{{ Detail.ctime }}</p>
                        <shareQcode :shareDate="{
        text: `/h5/pages/guideDetial/guideDetial?id=${Detail.id}`,
    }" />
                        <div class="GuideDetail_ct">
                            <p v-html="Detail.content"></p>
                        </div>
                    </div>
                </el-col>
                <el-col :span="5">
                    <el-row v-for="(item, index) in detail_list" :key="item.id" class="list">
                        <border_left_text :title="item.name" />
                        <el-col :span="24" v-for="(el, i) in item.list" class="flex"
                            style="align-items: center;margin: 10px 0;">
                            <el-row type="flex" justify="space-between">
                                <el-col :span="6" v-if="el.image != ''">
                                    <img @click="goDetail(el)" class="list_image" :src="el.image" alt="">
                                </el-col>
                                <el-col :span="el.image != '' ? 17 : 24">
                                    <h4 class="list_title" @click="goDetail(el)">{{ el.title }}</h4>
                                    <p @click="goDetail(el)" class=" list_des">{{ el.des.length > 35 ? el.des.slice(0,
        30) + '...' : el.des }}
                                    </p>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>

    </div>
</template>

<script>
import shareQcode from '../components/shareQcode.vue'
import border_left_text from '@/components/border_left_text.vue'
export default {
    name: '',
    components: {
        shareQcode,
        border_left_text
    },
    created() { },
    mounted() {
        this.getDetail()

    },
    data() {
        return {
            fullscreenLoading: false,
            Detail: {},
            detail_list: [],
        }
    },
    methods: {
        getDetail() {
            this.curlGet('/api/article/detail', {
                id: this.$route.query.id
            }).then(res => {
                // console.log(res);

                if (res.data.code) {
                    this.Detail = res.data.data
                    this.get_details_list()
                }

            })
        },
        get_details_list() {
            this.curlGet('/api/index/guide').then(res => {

                if (res.data.code) {
                    let detail_name = ['营销技巧', '拍摄技巧', '写稿技巧']
                    // let date = []
                    // for (const key in res.data.data) {
                    //     date.push({})
                    // }

                    for (const key in res.data.data) {
                        this.detail_list.push({
                            id: key - 2,
                            name: detail_name[key - 2]
                        })

                        this.$set(this.detail_list[key - 2], 'list', [])
                        res.data.data[key].forEach((item, index) => {

                            if (item.id != this.Detail.id && this.detail_list[key - 2].list.length < 3) {
                                this.detail_list[key - 2].list.push(item)
                            }

                        });
                        console.log('key', key);
                    }
                    console.log(this.detail_list);
                }
            })
        },
        goDetail(el) {
            console.log(el);
            this.$router.push({
                path: '/GuideDetail',
                query: {
                    id: el.id
                },
            })
            // window.open(url.href)
        },
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/Guide';

.GuideDetail_ct p {
    font-size: 1.33rem;
    color: #333333;
}
</style>
