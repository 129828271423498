<template>
  <div>
    <h3 class="title">{{ title }}</h3>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    title: String,
  },
  created() { },
  mounted() { },
  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang=scss scoped>
.title {
  color: #333;
  font-size: 16px;
  padding-left:20px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  margin-bottom: 10px;
  &::before {
    content: "";
    height: 100%;
    border: 1.5px solid #026bbc;
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>