<template>
    <div class="share flex">
        <span class="share_text">分享至:</span>
        <div class="share_img" @click.prevent="qCodeShow = true">
           
            <div class="Qcode" v-show="qCodeShow">
                <span class="close" @click.stop="qCodeShow = false">×</span>
                <vue-qr ref="qrCode" :text="pathUrl + shareDate.text" :logoSrc="require('@/assets/share/logo.png')"
                    :color-dark="'rgba(16, 140, 221, 1)'"></vue-qr>
                <p>用微信扫码二维码</p>
                <p>分享至好友和朋友圈</p>
            </div>

        </div>

    </div>
</template>

<script>
import VueQr from 'vue-qr'
export default {
    name: '',
    components: {
        VueQr
    },
    props: {
        shareDate: Object,
    },
    created() { },
    mounted() { },
    data() {
        return {
            pathUrl: window.location.origin,
            qCodeShow:false,
        }
    },
    methods: {},
}
</script>

<style lang=scss scoped>
@import '@/scss/shareQcode'
</style>